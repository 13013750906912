.management-team-caption {
  margin-bottom: 20px;
}

.leader-item-list {
  margin: -10px;
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin-top: 15px;
}

.leader-item {
  cursor: pointer;
}

.leader-item-list .leader-item,
.leader-item-list .partner-item {
  padding: 10px;
  white-space: normal;
  box-sizing: border-box;
  flex: 1;
}

.leader {
  background: white;
  display: flex;
  border-bottom: 3px solid #dc1e34;
  white-space: normal;
  white-space: initial;
  position: relative;
}

.leader::before {
  content: '';
  display: block;
  position: absolute;
  border-right: 16px solid transparent;
  border-left: 16px solid transparent;
  border-bottom: 16px solid #bababa;
  bottom: -24px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  transition: all 400ms ease;
}

.leader .field_picture {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 205px;
}

.leader .field_picture img {
  display: block;
  width: auto;
  height: 180px;
}

.leader .info-card-data {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px 10px;
  padding-left: 0px;
  border-right: 1px solid #f1f4f9;
  border-top: 1px solid #f1f4f9;
}

.leader .info-card-data::before {
  height: 60px;
  width: 1px;
  background: #f1f4f9;
  top: 0;
  left: 30px;
}

.leader h3 {
  margin-top: 20px;
}

.leader .title {
  font-size: 30px;
  padding-bottom: 15px;
  display: block;
  color: #181d22;
  font-weight: 900;
  line-height: 1em;
}

.leader .field_role {
  color: #666;
  text-transform: uppercase;
  line-height: 1.3em;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.leader a.info-box-toggle {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  text-align: center;
  border: 1px solid #f1f4f9;
  color: #dc1e34;
  text-decoration: none;
}

.leader a.info-box-toggle::after {
  content: '+';
}

.leader .info-card-data::after {
  height: 5px;
  width: 5px;
  background: #dc1e34;
  top: 60px;
  left: calc(30px - 2px);
}

.leader .hide {
  display: none;
}

.item-list {
  width: 100%;
}

.info-box-content-wrapper {
  position: relative;
  flex-basis: 100%;
  padding: 10px;
}

.info-box-content-wrapper .close {
  position: absolute;
  right: 25px;
  top: 28px;
  width: 31px;
  height: 31px;
  background-color: #dc1e34;
  opacity: 1 !important;
  cursor: pointer;
}

.info-box-content-wrapper .close:before,
.info-box-content-wrapper .close:after {
  position: absolute;
  left: 15px;
  top: 8px;
  content: ' ';
  height: 16px;
  width: 2px;
  background-color: #fff;
}

.info-box-content-wrapper .close:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.info-box-content-wrapper .close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.info-box-content-wrapper .wrapper {
  display: flex;
  flex-flow: row;
  background: #f1f4f9;
  border: 1px solid #c9cbce;
  padding: 3em;
  border-bottom: 3px solid #dc1e34;
  align-items: flex-start;
}

.info-box-content-wrapper .basic-info {
  flex-basis: 33.3%;
}

.info-box-content-wrapper .title {
  font-size: 45px;
  padding-bottom: 15px;
  display: block;
  color: #181d22;
  font-weight: 900;
  line-height: 1em;
}

.info-box-content-wrapper .field_role {
  color: #666;
  text-transform: uppercase;
  line-height: 1.3em;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.4px;
}

.info-box-content-wrapper .divider {
  flex-shrink: 0;
  margin: 0 4em;
  border-right: 1px solid #c9cbce;
  border-bottom: 1px solid #c9cbce;
}

.info-box-content-wrapper .description {
  flex-basis: 66.6%;
}

.info-box-content-wrapper .body {
  font-size: 18px;
  line-height: 1.7em;
  color: #33373b;
}

.about-image-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.about-image-text.reverse {
  flex-direction: row-reverse;
}

.about-image-text > div {
  width: 50%;
  padding: 40px;
}

.about-image-text .image img {
  max-width: 100%;
  width: 100%;
}

.full-image img {
  max-width: 100%;
  width: 100%;
}

.three-colmuns {
  display: flex;
  margin: 20px 0px;
  justify-content: space-between;
}

.three-colmuns .column {
  justify-content: center;
  width: 33%;
  border: 1px solid #f66b5d;
  text-align: justify;
  padding: 20px 20px;
}
