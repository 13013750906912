.profile-container{
    display:flex;
    flex-wrap: wrap;
}
.value-parent{
    margin:10px 0px;
    margin-right:20px;
}
.heading-parent{
    width:100%;
    background-color: #eee;
    padding:5px;
    border-radius: 5px;
}
.full-width{
    width:100%;
}

.block-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    border: 1px solid #999;
    padding-bottom: 10px;
    padding-left: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .block-heading {
    background-color: #efeaae;
    padding: 5px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #aeaeae;
    font-size: 18px;
    font-weight: bold;
    color: #233d63;
    margin-bottom: 0px;
  }
  .user-info-name{
    font-weight:bold;
  }
.services-explored{
    width:100%;
}
.detail-table{
    width:100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.detail-table tr th {
    background-color: #008b8b;
    color: #fff;
    padding: 6px 24px 6px 16px;
    border: 1px solid #ccc;
}
.services-explored{
    margin-top:20px;
}
.flexCenter { 
    padding: 10px;
    justify-content: center;
    align-content: center;
    }
    .main {
        margin-right: 10px;
        margin-top: 10px;
        width:220px;
        margin-bottom:0px;
        position:relative;
    }
    .card1 {
        text-align: right;
        padding: 10px;
        min-height: 40px;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        background-color: #fff;
        border: 1px solid #ccc;
    }
    .img{
        height:53px;
        position:absolute;
        left:5px;
        top:15px;
        z-index: 1;
    }    