.service-items-icon-image {
  width: 70px;
}

.server-error {
  margin-bottom: 10px;
  color: red;
  font-weight: bold;
  font-size: large;
  text-align: center;
}

.register-button {
  margin: 0px auto;
  display: block;
}

.service-category-logo {
  width: 200px;
  height: 300px;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.footer-left-blocks {
  display: flex;
  padding-right: 20px;
  padding-top: 10px;
}

.footer-wrapper .footer-widgets-grid .footer-left .footer-left-blocks .desc {
  margin-top: 0px;
  font-size: 12px;
  line-height: 22px;
  margin-right: 10px;
}

.footer-certificate {
  height: 100px;
  padding: 10px;
  padding-right: 0px;
}

body p {
  text-align: justify;
}

.service-items-title {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  text-transform: capitalize;
  transition: 0.3s;
  text-align: center;
}

.slider-category-description {
  font-size: 16px;
  color: #fff;
}

.service-slider-details {
  bottom: 20px;
  left: 10px;
  background-color: #fff;
  padding: 5px;
}

.about-us-wrapper .sec-heading .sec__title {
  color: #233d63;
}

.services-items-slider .item {
  cursor: pointer;
}

.services-items-slider .owl-theme .owl-nav {
  position: absolute;
  top: 38%;
  right: -18px;
  left: -18px;
}

.services-items-slider .owl-carousel .owl-nav .owl-prev {
  position: absolute;
  left: 0px;
  font-size: 50px;
  line-height: 60px;
}

.services-items-slider .owl-carousel .owl-nav .owl-next {
  position: absolute;
  right: 0px;
  font-size: 50px;
  line-height: 60px;
}

.h-service {
  padding-bottom: 10px;
  padding-top: 20px;
  background-color: #233d63;
  padding-bottom: 40px;
}

.h-service .sec-heading .sec__meta {
  color: #fff;
}

.h-service .sec-heading .sec__title {
  color: #fff;
}

.service-items-title {
  background-color: #fff;
  color: #233d63;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-whychoose-image {
  width: 555px;
  height: 354px;
}

.social h3 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 35px;
  color: #fff;
  margin-top: 10px;
}

.social ul {
  display: flex;
  align-items: center;
}

.social ul li {
  margin-right: 5px;
}

.social ul li:last-child {
  margin-right: 0;
}

.social ul li a {
  position: relative;
  display: block;
  color: #fff;
  width: 35px;
  height: 35px;
  line-height: 39px;
  background-color: #677286;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  transition: 0.3s;
}

.social ul li a:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #233d63;
}

.footer-address li {
  margin-bottom: 15px;
}

.footer-address li:last-child {
  margin-bottom: 0;
}

.footer-address li a {
  color: #a0aec6;
  transition: all 0.3s;
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.footer-address li a .icon {
  width: 35px;
  display: inline-block;
  height: 35px;
  line-height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
  text-align: center;
  margin-right: 10px;
  position: relative;
}

.footer-address li a .icon:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #233d63;
}

.header-area .header-menu-wrapper .header-menu-flex .header-menu-right nav ul li {
  padding-right: 15px;
  font-size: 14px;
}

.footer-address li a:hover .icon,
.social ul li a:hover,
.widget_tags ul li a:hover {
  background: #f66b5d;
}

.team-wrapper .team-grid .team img.industry-image {
  width: 100%;
  max-height: 202px;
}

.main-header-logo {
  width: 250px;
}

.footer-logo {
  width: 100px;
}

.sub-category {
  margin-left: 40px;
}

.service-details-top {
  display: flex;
}

.service-details-wrapper .service-details-grid .service-sidebar {
  grid-column: span 2;
}

.service-details-wrapper .service-details-grid .service-content-wrap {
  grid-column: span 6;
}

.blog-wrapper .blog-grid {
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-column-gap: 15px;
}

.blog-wrapper .blog-grid .blog {
  margin-bottom: 20px;
  padding-top: 10px;
}

.blog-wrapper .blog-grid .blog .blog-img-box img {
  border-radius: 15px;
  width: auto;
  max-width: 100px;
  height: 75px;
}

.blog-wrapper .blog-grid .blog .blog-img-box {
  text-align: center;
}

.blog-wrapper .blog-grid .blog .blog-content-box {
  padding: 10px;
  height: 83px;
  text-align: center;
}

.team-member-detail {
  background-color: white;
  padding: 10px;
  margin-top: 50px;
  height: 478px;
  overflow: auto;
}

.team-details-wrapper {
  position: relative;
  padding: 10px;
}

.member-parent {
  position: relative;
}

.team-details-wrapper .team-details-grid-top .team-details-img {
  grid-column: span 1;
  position: relative;
  z-index: 1;
}

.close-parent {
  text-align: center;
}

.close-parent button {
  border: 1px solid #999;
  padding: 7px 20px;
  background-color: #233d63;
  color: #fff;
}

.partner-item {
  margin-bottom: 5px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ccc;
  background-color: #fcfcfc;
}

.partner-item .blog-meta-name {
  width: 150px;
  font-weight: 500;
  display: inline-block;
}

.blog-wrapper .blog-grid .blog .blog-content-box .blog-title {
  font-size: 16px;
  margin-bottom: 0px;
  text-align: center;
  height: 55px;
  overflow: hidden;
  line-height: 23px;
}

.team-wrapper .team-grid {
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.category-name {
  margin-bottom: 20px;
  text-align: center;
}

h1.sub-category-name {
  margin: 20px;
  text-align: center;
}

.category-image-parent {
  text-align: center;
  margin: 20px;
}

.category-image {
  height: 172px;
  width: 251px;
  border-radius: 15px;
}

.service-sub-categories {
  display: flex;
  margin: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.service-sub-categories .sub-category-block {
  width: 260px;
  height: 275px;
  margin: 20px 0px;
  border: 1px solid #eee;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border-radius: 15px;
}

.service-sub-categories .sub-category-block:hover {
  border: 1px solid #999;
}

.sub-category-block .category-image {
  width: 200px;
  height: 150px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 15px;
}

.cat-link.selected {
  background-color: #f66b5d;
  border-color: #f66b5d;
  color: #fff;
  border-radius: 10px 0 10px 0;
}

.cat-link.selected a {
  color: #fff;
}

.plain-pricing-table {
  padding: 20px;
}

.plain-pricing-table .pricing-description {
  font-size: 18px;
  padding: 10px;
}

.pricing-wrapper.category-pricing {
  padding: 25px;
  background: #eee;
}

.pricing-wrapper.category-pricing .sec-heading .sec__title {
  margin-bottom: 16px;
}

.pricing-wrapper.category-pricing .pricing-plan-grid .pricing-plan-box .price {
  font-size: 29px;
  font-weight: 700;
  margin-bottom: 4px;
}

.pricing-wrapper.category-pricing .pricing-plan-grid .pricing-plan-box .price sup {
  font-size: 13px;
  top: 3px;
  vertical-align: top;
}

.pricing-wrapper.category-pricing .pricing-plan-grid .pricing-plan-box {
  background: var(--white_color);
  padding: 2px 0 0px 0;
  border-radius: 4px;
}

.pricing-wrapper.category-pricing .pricing-plan-grid .pricing-plan-box .title {
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 9px;
}

.sec-heading .sec__title {
  font-size: 29px;
  font-weight: 700;
  margin-bottom: 23px;
  line-height: 55px;
}

.pricing-wrapper.category-pricing .pricing-plan-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-column-gap: 9px;
  text-align: center;
  padding: 0px 10px;
}

.service-content-wrap .service-content h1 {
  font-size: 23px;
}

.service-content-wrap .service-content h1.sub-category-name {
  font-size: 23px;
  border: 1px solid #999;
  padding: 5px 0px;
}

.service-category-actions {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.service-category-actions .btn {
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #000;
  line-height: 24px;
  text-transform: capitalize;
  transition: all 0.3s;
  position: relative;
  display: flex;
  cursor: pointer;
  border: 0px solid #ccc;
}

.btn .icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.share-requirement-form .contact-form-grid form .form-grid {
  grid-template-columns: repeat(auto-fit, minmax(257px, 1fr));
  grid-column-gap: 30px;
}

.inner-heading {
  font-size: 20px;
  color: #233d63;
  font-weight: 700;
  line-height: 28px;
  display: block;
  margin: 10px 0px;
  text-align: center;
}

.inner-heading-left {
  font-size: 20px;
  color: #233d63;
  font-weight: 700;
  line-height: 28px;
  display: block;
  margin: 10px 0px;
  text-align: left;
}

.share-requirement-form {
  background-color: #eee;
  padding: 15px;
}

.meet-buddy-list {
  background-color: #eee;
  padding: 15px;
}

.service-providers-list {
  background-color: #eee;
  padding: 15px;
}

.text-muted {
  color: #99a0ac !important;
}

.block,
.card {
  background: #fff;
  border-width: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}

.avatar {
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 500px;
  box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
}

.avatar img {
  border-radius: inherit;
  width: 100%;
}

.gd-primary {
  color: #fff;
  border: none;
  background: #448bff linear-gradient(45deg, #448bff, #44e9ff);
}

.gd-success {
  color: #fff;
  border: none;
  background: #31c971 linear-gradient(45deg, #31c971, #3dc931);
}

.gd-info {
  color: #fff;
  border: none;
  background: #14bae4 linear-gradient(45deg, #14bae4, #14e4a6);
}

.gd-warning {
  color: #fff;
  border: none;
  background: #f4c414 linear-gradient(45deg, #f4c414, #f45414);
}

.list {
  padding-left: 0;
  padding-right: 0;
}

.list-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.list-row .list-item {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 0.625rem;
}

.list-row .list-item>* {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.no-wrap {
  white-space: nowrap;
}

.text-color {
  color: #5e676f;
}

.text-gd {
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}

.text-sm {
  font-size: 0.825rem;
}

.h-1x {
  height: 1.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.w-48 {
  width: 48px !important;
  height: 48px !important;
}

a:link {
  text-decoration: none;
}

.user-details {
  width: 160px;
}

.meet-buddy-list .list {
  display: flex;
  flex-wrap: wrap;
  background: transparent;
  justify-content: space-evenly;
}

.meet-buddy-list .list .list-item {
  margin-right: 15px;
  background: #fff;
  margin-bottom: 0px;
}

.user-status {
  color: green;
}

.provider-details-top {
  display: flex;
  overflow: hidden;
}

.provider-name {
  font-size: 12px;
  color: #233d63;
  font-weight: 700;
}

.provider-details {
  font-size: 11px;
}

.service-providers-list .list {
  display: flex;
  flex-wrap: wrap;
  background: transparent;
  justify-content: space-evenly;
}

.service-providers-list .list-item {
  margin-right: 1%;
  background: #fff;
  margin-bottom: 20px;
  padding: 10px;
  cursor: pointer;
  width: 43%;
}

.service-provider-image {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 15px;
}

.category-icons .icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.sub-category-name {
  font-size: 14px;
  font-weight: bold;
}

.header-area .header-menu-wrapper .header-menu-flex .header-menu-right .header-menu-right-flex .header-menu-searchform {
  position: absolute;
  right: 0;
  top: 80px;
  width: 330px;
  border: 5px solid var(--gray_color);
  border-radius: 8px;
  background: var(--white_color);
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
}

.search-searvices {
  margin-bottom: 20px;
}

.search-searvices .header-menu-searchform.active {
  opacity: 1;
  visibility: visible;
  top: 55px;
}

.search-searvices .header-menu-searchform form {
  display: flex;
  align-items: center;
}

.search-searvices .header-menu-searchform form input {
  width: 100%;
  background-color: #fff;
  padding: 15px 51px 15px 20px;
  border: 1px solid #eeeeee;
  font-weight: 500;
  font-size: 14px;
  resize: none;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  transition: all 0.3s;
  margin: 0;
  line-height: initial;
}

.search-searvices .header-menu-searchform form .form-icon {
  right: 0;
  background: #eeeeee;
  height: 100%;
  line-height: 49px;
  width: 50px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  z-index: 9;
}

.search-searvices .header-menu-searchform form .form-icon:hover {
  background: var(--primary_color);
  color: #fff;
}

.sub-category-name .icon {
  font-size: 20px;
  border: 1px solid #999;
  margin-top: 0px;
  background: #eee;
}

.list-toggle {
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: 1px solid #999;
  border-radius: 5px;
  background-color: thistle;
}

.category-icon {
  width: 24px;
  height: 24px;
  border-radius: 18px;
  border: 1px solid #999;
}

.service-details-wrapper .service-details-grid .service-sidebar-icon {
  width: 30px;
}

.popup-backdrop {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(200, 200, 200, 0.5);
}

.main-popup {}

.search-searvices .header-menu-searchform input {
  height: auto;
}

.partners .theme-button .icon {
  position: absolute;
  top: 56%;
  right: 3px;
  font-size: 14px;
  transition: all 0.3s;
  transform: translateY(-50%);
}

.partners .theme-button {
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  padding: 0 20px 0 10px;
  cursor: pointer;
}

.hero-slider-wrapper .hero-slider-item .hero-slider-content h1 {
  display: none;
}

.hero-slider-wrapper .hero-slider-item .hero-slider-content .hero-slider-btns a {
  background: #233d63;
  color: #fff;
  font-weight: 600;
}

.hero-slider-wrapper .hero-slider-item .hero-slider-content p {
  background-color: rgba(0, 0, 255, 0.5);
  font-size: 24px;
  line-height: 30px;
  display: inline-block;
  padding: 10px 20px;
}

.hero-slider-wrapper .hero-slider-item .hero-slider-content p span {
  font-size: 16px;
  line-height: 30px;
}

.breadcrumb-wrapper .breadcrumb-content {
  padding: 0px 0 59px 0;
  position: relative;
}

.breadcrumb-wrapper {
  padding: 132px 0 0 0;
}

.btn.selected {
  background: antiquewhite;
}

.mgtp20 {
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 90%;
  }

  .my-model-popup {
    max-width: 800px;
  }
}

@media (max-width: 1024px) {
  .team-wrapper {
    position: relative;
    padding: 31px 0 66px 0;
  }

  .team-wrapper .sec-heading {
    margin-bottom: 0px;
  }

  .sec-heading .sec__meta {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .sec-heading .sec__title {
    font-size: 21px;
    margin-bottom: 0px;
    line-height: 42px;
  }

  .service-details-top {
    flex-direction: column;
  }

  .list-toggle {
    display: none;
  }

  .services-icons.display-onmobile {
    flex-wrap: wrap;
  }

  .display-onmobile .cat-link-icon {
    margin: 0px 10px;
    padding: 5px 10px;
    background-color: #233d63;
    color: #fff;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 15px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.services-icons.display-onmobile {
  display: none;
}

.services-icons.display-desktop {
  display: flex;
}

.side-menu-open .menu__bar {
  background-color: #fff;
  height: 4px;
}

.side-menu-open .menu__bar:nth-child(2) {
  margin-left: 0px;
}

.service-details-wrapper {
  padding: 20px 0 20px 0;
}

.tabs-wrapper {
  background-color: #f6f8f9;
  padding: 20px 0 10px 0;
}

.testimonial-wrapper {
  background: #f6f8f9;
}

.ctaform-wrapper {
  background: #eeeeee;
  padding: 20px 0 20px 0;
}

.services-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cat-link-icon {
  margin: 0px 10px;
  padding: 5px 10px;
  background-color: #233d63;
  color: #fff;
  border-radius: 5px;
  font-size: 32px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cat-link-icon:hover {
  background-color: #f66b5d;
}

.cat-link-icon.selected {
  background-color: #f66b5d;
}

.account-verify {
  text-align: center;
  margin: 40px 0px;
}

.invalid-details {
  font-size: 16px;
  color: #f00;
  font-weight: 700;
  margin: 20px;
  text-align: center;
}

.profile-form {}

.register-note {
  font-size: 10px;
  color: #f00;
}

.header-userinfo {
  color: #fff;
}

.user-logout {
  cursor: pointer;
  margin-left: 15px;
}

.signup-form-wrapper .signup-form-box {
  max-width: 685px;
  padding: 15px;
}

.signup-form-wrapper {
  padding: 42px 0 50px;
  position: relative;
  overflow: hidden;
}

.hero-slider-wrapper .hero-slider-item .hero-slider-content h1 {
  font-size: 48px;
}

.breadcrumb-wrapper.small .breadcrumb-content {
  padding: 0px 0 20px 0;
  position: relative;
}

.category-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-wrapper .footer-widgets-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1px;
}

.footer-wrapper .footer-widgets-grid .footer-help-links {
  padding-left: 5px;
}

.footer-wrapper .copyright-wrap {
  position: relative;
  padding: 21px 0 35px 0;
  margin-top: 10px;
  border-top: 1px solid rgba(160, 174, 198, 0.1);
}

.footer-wrapper .bottom-menu-wrap {
  position: relative;
  padding: 21px 0 15px 0;
  margin-top: 10px;
  border-top: 1px solid rgba(160, 174, 198, 0.1);
}

.bottom-menu {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 0px;
}

.register-link {
  color: #007bff;
  cursor: pointer;
}

.bottom-menu li {
  margin: 0px 15px;
}

.bottom-menu li a {
  font-size: 15px;
  font-weight: 600;
  color: #a0aec6 !important;
}

.back-to-main-category-parent {
  text-align: center;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  margin-top: 10px;
}

.back-to-main-category {
  text-align: center;
  margin: 0px auto;
  background-color: #233d63;
  padding: 5px 20px;
  cursor: pointer;
  display: inline-block;
  color: #fff;
}

.back-to-parent-category {
  text-align: center;
  margin: 0px auto;
  margin-right: 10px;
  background-color: #233d63;
  padding: 5px 20px;
  cursor: pointer;
  display: inline-block;
  color: #fff;
}

.form-controls-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  align-items: flex-start;
}

.signup-form-wrapper .signup-form-box .signup-form .privacy-policy {
  margin-bottom: 0px;
}

.form-controls-container>.form-control {
  width: 48%;
  margin-bottom: 15px;
}

.form-controls-container .form-control-parent {
  width: 48%;
  margin-bottom: 15px;
}

.form-controls-container .form-control-parent .form-control {
  margin-bottom: 0px;
}

.form-controls-container>.full-width-control {
  width: 98%;
}

.form-controls-container .full-width-control-parent {
  width: 98%;
}

.login-form-wrapper .signup-form-box {
  max-width: 500px;
}

.signup-form-wrapper .signup-form-box .signup-form-top h1 {
  font-size: 28px;
}

.profile-wrapper {
  padding: 20px;
}

.work-address {
  margin: 15px 0px;
}

.form-control.no-border {
  border: 0px;
}

.services-items-grid .service-items .service-items-description {
  text-align: justify;
}

.about-wrapper .about-right .about_img_1 {
  position: initial;
}

.about-wrapper .about-right .about_img_0 {
  z-index: 1;
  width: 60%;
}

.about-images {
  text-align: center;
}

.about-wrapper .about-right .about_img_2 {
  z-index: 3;
  width: 306px;
  margin-top: 0px;
}

.header-bar-cta-btn {
  position: relative;
}

.header-userinfo {
  margin-right: 20px;
}

.my-account-menu {
  background-color: #eeeeee;
  border: 0px solid #999;
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
  border-top: 0px;
  width: 94px;
}

.myaccount {
  width: 94px;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  line-height: 35px;
  padding: 0px 5px 0 5px;
  background-color: #fff;
  border-radius: 4px;
}

.myaccount:hover {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.header-bar-my-account-menu:hover .my-account-menu {
  display: flex;
}

.header-bar-my-account-menu:hover .myaccount {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.my-account-menu-item {
  margin: 0px;
  margin-bottom: 5px;
  text-align: center;
  border-bottom: 0px solid #000;
  padding: 5px 10px;
  font-weight: 700;
}

.my-account-menu-item:hover {
  color: #f66b5d;
}

.signup-form-wrapper .signup-form-box .already-account {
  text-align: center;
}

.servize-buddy-program {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.servize-buddy-program li {
  width: 30%;
  margin: 20px 0px;
  border: 1px solid #f66b5d;
  padding: 20px 10px;
  border-radius: 10px;
  text-align: center;
}

.sbicon {
  font-size: 50px;
  color: #233d63;
  margin-bottom: 10px;
}

.about-values {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0px;
  background-color: #f0f8ff;
}

.about-values .value {
  width: 47%;
  margin: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.about-values .value .title {
  font-weight: bold;
  font-size: 24px;
  margin: 20px 0px;
}

.discription {
  color: #434e73;
  font-size: 20px;
  line-height: 30px;
}

.about-values .value:hover {
  background-color: #fff;
}

.our-values-caption {
  text-align: center;
  margin: 20px;
  font-size: 40px;
}

.management-team-caption {
  text-align: center;
  margin: 40px;
  font-size: 40px;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
}

.team-members .team-member {
  /* width: 250px; */
  margin: 10px;
  position: relative;
  flex: 1;
}

.team-member h4 {
  font-size: 18px;
}

.mamber-open img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.member-name {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.mamber-open {
  left: 0px;
  top: 0px;
}

.team-members .hoverCard {
  /* position: absolute; */
  left: 0px;
  top: 0px;
  background-color: #fff;
  bottom: 0px;
}

.team-member:hover .hoverCard {
  display: block;
}

.abourCardDivision .hoverCard .boxInnerBlock {
  overflow: hidden;
  margin-bottom: 15px;
}

.abourCardDivision .hoverCard .firstHalfBlock {
  width: 30%;
  float: left;
  padding-left: 0;
}

.abourCardDivision .hoverCard .firstHalfBlock img {
  max-width: 100%;
  height: none;
  max-height: 240px;
}

.abourCardDivision .hoverCard .secHalfBlock {
  width: 70%;
  float: left;
  padding: 10px 0 0 10px;
}

.abourCardDivision .hoverCard .card-body {
  padding: 0;
  text-align: justify;
  font-size: 18px;
  line-height: 24px;
}

.footer-left p {
  text-align: left;
}

.sec-heading .sec__meta {
  text-align: center;
}

.values-list-home {
  margin: 20px 0px;
}

.about-us-content {
  font-size: 18px;
  margin: 20px auto;
  text-align: justify;
}

.values-list-home table {
  max-width: 80%;
  margin: 20px auto;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 16px;
  font-family: sans-serif;
  width: 80%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #233d63;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #233d63;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.input-error {
  color: red;
  font-size: 12px;
}

.sec-heading {
  text-align: center;
}

.tabs-full {
  margin: 0px auto;
}

.footer-wrapper:before {
  transform: rotate(0deg);
}

.value .sbicon .icon {
  width: 40px;
  height: auto;
}

.about-left-full {
  font-size: 18px;
  line-height: 25px;
  /* background-color: #f0f8ff; */
  color: #212529;
}

.about-left-full p {
  margin-bottom: 20px;
  text-align: justify;
}

.tabs-wrapper .tabs-content-wrap .tabs-content-flex p {
  margin-bottom: 10px;
}

.about-wrapper {
  position: relative;
  overflow: hidden;
  padding: 50px 0 113px 0;
}

.service-title-home .sec__meta {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 55px;
}

.service-title-home .sec__title {
  font-size: 19px;
  text-transform: capitalize;
  margin-bottom: 5px;
  font-weight: 100;
}

.home-about-us .about-values {
  background-color: #fff;
}

.tabs-wrapper .tabs-content-wrap .tabs-nav {
  justify-content: center;
}

.tabs-wrapper .tabs-content-wrap .tabs-nav li {
  margin-right: 50px;
}

.home-about-us .about-values .value {
  display: flex;
}

.home-about-us .about-values .details {
  text-align: left;
}

.home-about-us .about-values .value .title {
  font-weight: bold;
  font-size: 21px;
  margin: 0px 0px 7px 0px;
}

.home-about-us .sbicon {
  margin-right: 15px;
}

.footer-address .title {
  color: #fff;
  font-weight: 700;
}

.footer-address .detail {
  color: #fff;
}

.social h3.our-offices {
  margin: 50px 0px 20px 0px;
}

.office-address {
  color: #fff;
}

.sbicon img {
  width: 150px;
  height: auto;
  border-radius: 15px;
}

.home-read-more {
  float: right;
  margin-top: -60px;
  line-height: 30px;
}

.social ul li a {
  background-color: #fdc40a;
  line-height: 30px;
}

.social ul li a:before {
  display: none;
}

.footer-wrapper .footer-widgets-grid .footer-left .desc {
  text-align: justify;
  margin-right: 20px;
}

.header-partner-auth-links {
  color: #fff;
  display: flex;
}

.link-parent {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
}

.link-parent a {
  color: #fff;
  font-weight: 700;
}

.link-caption {
  font-size: 16px;
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
}

.page-banner {
  max-height: 350px;
  overflow: hidden;
  position: relative;
}

.contact-form-link {
  position: absolute;
  top: 10px;
  right: 6%;
}

.contact-form-link a {
  background-color: #03a84e;
}

.contact-form-link a:hover {
  background-color: #233d63;
}

.map-image {
  width: 100%;
}

.get-directions {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.map-image-parent {
  height: 302px;
  overflow: hidden;
  text-align: center;
  background-color: #efefef;
  padding: 0px;
}

.contact-our-team-btn {
  cursor: pointer;
  display: inline-block;
}

.contact-form-wrapper {
  padding-top: 20px;
}

.contact-us-details {
  margin: 15px 0px;
  font-size: 18px;
  font-weight: bold;
  text-align: justify;
}

.contact-us-bullet {
  list-style-type: disc;
}

.login-message {
  margin: 10px;
  text-align: center;
  color: red;
  font-size: 14px;
}

.contact-us-bullet li {
  list-style-type: disc;
  margin-left: 30px;
  line-height: 40px;
  font-size: 18px;
}

.page-banner .page-banner-image {
  width: 100%;
}

.contact-form-wrapper .contact-form-map-grid {
  padding: 10px 0 0 0;
}

.contact-form-wrapper .contact-form-grid {
  grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
  grid-column-gap: 30px;
  margin-bottom: 30px;
}

.contact-us-form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-us-form-container>div {
  width: 30%;
}

.contact-us-form-container select {
  width: 100%;
}

.contact-form-wrapper .contact-form-map-grid {
  margin-bottom: 20px;
}

.login-with-otp {
  margin-left: 20px;
}

.login-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-type-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.user-type-selection>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  border: 1px solid #e1e1e1;
  background-color: #f3f3f3;
  padding: 20px;
  width: 350px;
}

.signup-caption {
  font-weight: 700;
  font-size: 20px;
  margin: 5px 0px;
  text-transform: uppercase;
}

.signup-subcaption {
  text-align: justify;
  margin: 10px 0px;
}

.signup-btn {
  margin-top: 10px;
  cursor: pointer;
}

#contactform {
  padding-top: 30px;
}

.subheading {
  margin: 20px 0px;
}

.contact-form-wrapper .contact-form-map-grid .right aside .contact-sidebar-infos .item .icon span:after {
  display: none;
}

.contact-form-wrapper .contact-form-map-grid .right aside .contact-sidebar-infos .item .icon span {
  line-height: 33px;
}

.contact-form-main-container {
  display: flex;
}

.contact-form-main-container .left {
  width: 455px;
  margin-right: 20px;
}

.contact-form-subheading {
  margin: 20px 0px;
  font-size: 18px;
  text-align: center;
}

.register-message {
  text-align: center;
  margin: 10px 0px;
  color: #f00;
  font-size: 18px;
  font-weight: bold;
}

.page-intro {
  font-size: 18px;
  width: 90%;
  margin: 20px auto;
}

.page-intro p {
  margin-bottom: 20px;
}

.blog-wrapper.blog-grid-page {
  padding: 20px 0 0 0;
}

.team-wrapper {
  position: relative;
  padding: 10px 0 90px 0;
}

.team-wrapper .sec-heading {
  margin-bottom: 10px;
}

.sec-heading .sec__meta {
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 5px;
  font-weight: 600;
}

.sec-heading .sec__title {
  margin-bottom: 0px;
}

.privacy-policy-container div {
  margin: 15px 0px;
  font-size: 18px;
}

.privacy-policy-container li strong {
  margin: 20px 0px;
  display: inline-block;
}

.contact-form-wrapper .contact-form-map-grid .left {
  grid-column: span 2;
}

.sidebar-social-profile ul li a {
  color: #fff;
  font-size: 18px;
  background-color: #fdc40a;
}

.login-page-banner {
  max-height: 170px;
}

.blog-wrapper .blog-grid .preffered-partners {
  display: flex;
  cursor: pointer;
}

.blog-wrapper .blog-grid .preffered-partners .blog-content-box .blog-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  text-align: left;
  height: 33px;
  overflow: hidden;
  line-height: 23px;
}

.blog-wrapper .blog-grid .preffered-partners .blog-content-box .blog-desc {
  font-size: 16px;
  margin-bottom: 0px;
  text-align: left;
  height: 25px;
  overflow: hidden;
  line-height: 23px;
  margin-top: 5px;
}

.partner-parent .partner-initial {
  display: flex;
}

.partner-parent .partner-initial img {
  margin-right: 20px;
}

.clientele {
  font-weight: bold;
  margin: 10px 0px;
  max-width: 100%;
}

.more-description {
  margin: 20px 0px;
}

.hero-slider-wrapper {
  position: relative;
  margin-top: 58px;
}

.buddy-benefits {
  padding: 0px 40px;
}

.contact-form-grid.career-page {
  display: block;
}

.benifits-container {
  display: flex;
  margin: 20px 0px;
  justify-content: space-between;
}

.benifit-parent {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0px 5px;
}

.ben-caption {
  font-weight: bold;
}

.ben-heading {
  margin: 20px;
  text-align: center;
  font-size: large;
  font-weight: bold;
}

.ben-heading-left {
  margin: 20px 0px;
  text-align: left;
  font-size: large;
  font-weight: bold;
}

.apply-here {
  line-height: 30px;
  cursor: pointer;
}

.contact-us-form-container>div {
  margin-bottom: 15px;
}

.contact-us-form-container>div input {
  margin-bottom: 0px;
}

#openings {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#openings td, #openings th {
  border: 1px solid #ddd;
  padding: 8px;
}

#openings tr:nth-child(even) {
  background-color: #f2f2f2;
}

#openings tr:hover {
  background-color: #ddd;
}

#openings th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.career-bottom-heading {
  margin: 20px 0px;
}

@media (max-width: 1200px) {
  .about-us-content {
    font-size: 18px;
    margin: 20px auto;
    text-align: justify;
  }

  .values-list-home table {
    max-width: 100%;
  }

  .styled-table {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .hero-slider-wrapper .hero-slider-item .hero-slider-content h1 {
    font-size: 30px;
  }

  .offset-2 {
    margin-left: 16.666667% !important;
  }

  .about-st1-wrapper {
    padding: 20px 0 20px 0;
  }

  .ctaform-wrapper {
    padding: 20px 0 20px 0;
  }

  .footer-wrapper {
    padding-top: 20px;
  }

  .tabs-wrapper .tabs-content-wrap .tabs-content-flex .tabs-right {
    padding: 10px;
  }

  .header-area .header-menu-wrapper .header-menu-flex {
    padding: 0px 0;
  }

  .breadcrumb-wrapper .breadcrumb-content h2 {
    font-size: 20px;
  }

  .breadcrumb-wrapper .breadcrumb-content {
    padding: 0 0 35px 0;
    position: relative;
  }

  .breadcrumb-wrapper {
    padding-top: 134px;
  }

  .services-icons.display-onmobile {
    display: flex;
  }

  .services-icons.display-desktop {
    display: none;
  }

  .service-category-actions span {
    display: none;
  }

  .d-grid {
    display: block;
  }

  .service-sidebar {
    display: none;
  }
}

.footer-left {
  width: 310px;
}

.footer-wrapper .footer-widgets-grid {
  display: flex;
  justify-content: space-between;
}

.auth-model-popup {
  max-width: 1024px;
}

.pricing-details {
  padding: 10px;
}

.pricing-details p {
  margin: 10px;
}

.pricing-details p strong {
  color: #000;
}

/* .auth-model-popup .close{
  display:none;
} */