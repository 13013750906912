/* Container needed to position the overlay. Adjust the width as needed */
.image-container {
    position: relative;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
  }
  
  /* Make the image to responsive */
  .image {
    width: 100%;
    height: auto;
  }
  .ben-image{
    transform: scale(1);
    transition: .3s ease;
  }
  /* The overlay effect (full height and width) - lays on top of the container and over the image */
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #008CBA;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: .3s ease;
    opacity: 0.5;
  }
  .overlay.ben1{
      background-color: #f5bf6a;
  }
  .overlay.ben2 {
      background-color: #002060;
  }
  .overlay.ben4{
      background-color: #616161;
  }
  
  /* When you mouse over the container, the overlay text will "zoom" in display */
  .image-container:hover .overlay {
    transform: scale(1.0);
  }

  .image-container:hover .ben-image {
    transform: scale(1.10);
  }
  
  /* Some text inside the overlay, which is positioned in the middle vertically and horizontally */
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }